import { tns } from "tiny-slider"
import {observeImages} from "./_lazy-image";

function initImageCarousel(id) {
    tns({
        container: '.carousel-container-' + id,
        mode: 'carousel',
        autoWidth: true,
        items: 1,
        gutter: 20,
        loop: true,
        autoplay: false,
        controlsContainer: '.image-controls-' + id,
        prevButton: '.image-previous-' + id,
        nextButton: '.image-next-' + id,
        navPosition: 'bottom',
        lazyload: false,
    });

    const images = document.querySelectorAll(`.carousel-container-${id} img.lazy`);
    observeImages(images);
}

window.initImageCarousel = initImageCarousel;