const productPage = document.querySelector(".single-products");

function formatTime(time, lang) {
	if (lang == "nb-NO") {
		return time.toLocaleTimeString("no-NB", {
			hour: "2-digit",
			minute: "2-digit",
		});
	} else {
		return time.toLocaleTimeString("en-US", {
			timeStyle: "short",
		});
	}
}

function formatDate(date, lang) {
	if (lang == "nb-NO") {
		return date.toLocaleDateString("no-NB", {
			day: "numeric",
			month: "long",
		});
	} else {
		return date.toLocaleDateString("en-US", {
			day: "numeric",
			month: "long",
		});
	}
}

// Get next departure from iTicket
if (productPage) {
	const productCode = productPage
		.querySelector(".product-details")
		.getAttribute("data-iticket-productcode");
	const departureTextField = productPage.querySelector(".next-departure");
	const departurePreTextField = productPage.querySelector(
		".next-departure-pre-text"
	);
	const loader = productPage.querySelector(".loader");
	const todayDateString = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format

	jQuery.ajax({
		type: "POST",
		url: rodne_scripts.ajax_url,
		data: {
			action: "get_product_departures",
			product_code: productCode,
		},
		success: function (output) {
			let departures = output["departures"] || [];

			if (departures?.length > 0) {
				departures = departures.filter((departure) => {
					return departure.availableCapacity > 0;
				});
			}

			const todayDepartures = departures.filter((departure) => {
				return (
					new Date(departure["datetime"])
						.toISOString()
						.split("T")[0] == todayDateString
				);
			});

			const hasDeparturesToday = todayDepartures.length > 0;

			if (departures.length > 0) {
				const firstAvailableDate = new Date(departures[0]["datetime"])
					.toISOString()
					.split("T")[0];

				if (hasDeparturesToday) {
					departures = todayDepartures;
				} else {
					departures = departures.filter((departure) => {
						return (
							new Date(departure["datetime"])
								.toISOString()
								.split("T")[0] == firstAvailableDate
						);
					});
				}

				const lang = document.documentElement.lang;
				let text = "";

				// Prefix the text with "Today" or the first available date
				if (hasDeparturesToday) {
					text = lang == "nb-NO" ? "I dag kl. " : "Today at ";
				} else {
					text =
						formatDate(new Date(firstAvailableDate), lang) +
						(lang === "nb-NO" ? " kl. " : " at ");
				}

				// Format all of the depature times
				const times = departures.map((departure) => {
					return formatTime(new Date(departure["datetime"]), lang);
				});

				// Join the n-1 times with a comma
				text += times
					.slice(0, Math.max(times.length - 1, 1))
					.join(", ");

				// Add the last departure time with a "and" separator
				if (times.length > 1) {
					text +=
						(lang == "nb-NO" ? " og " : " and ") +
						times[times.length - 1];
				}

				departureTextField.innerHTML = text;

				// If there are multiple departures, change the pre-text to plural
				if (departures.length > 1) {
					departurePreTextField.innerHTML =
						departurePreTextField.dataset.multipleDepaturesText +
						":";
				}
			} else {
				const noFutureDepartures = productPage.querySelector(
					".no-future-departures"
				);

				noFutureDepartures.classList.remove("hidden");
				departureTextField.classList.add("hidden");
			}

			// Hide loader
			loader.classList.add("hidden");
		},
	});
}

// Connect product details links to accordion
if (productPage) {
	const productDetails = document.querySelector(".product-details");
	const allLinks = productDetails.querySelectorAll("a");
	const accordions = document.querySelectorAll(".accordion .element-wrapper");

	// put accordions in object where data-id is key
	const accordionObj = {};
	accordions.forEach((accordion) => {
		const id = accordion.getAttribute("data-id");
		accordionObj[id] = accordion;
	});

	//get the url and split out #

	allLinks.forEach((link) => {
		link.addEventListener("click", function (e) {
			// Get href attribute without #
			const href = link.getAttribute("href").split("#")[1];

			// Check if href is in accordionObj
			if (accordionObj[href]) {
				e.preventDefault();
				// Open accoridon with class
				accordionObj[href].classList.add("show-dropdown");

				// Scroll down to accordion
				const offset = accordionObj[href].offsetTop - 100;
				window.scrollTo({
					top: offset,
					behavior: "smooth",
				});
			}
		});
	});
}
